<template>
  <div class="top-header">
    <div class="line"></div>
    <div class="page-title">
      <slot></slot>
    </div>
  </div>
</template>
<script type="text/ecmascript-6">
export default {};
</script>
<style lang="css" scoped>
.top-header {
  overflow: hidden;
}
.top-header .line {
  background: #28282e;
  height: 23px;
  width: 100%;
  position: fixed;
  z-index: 666;
}
.top-header .page-title {
  height: 40px;
  line-height: 40px;
  padding-left: 15px;
  background-color: #fff;
  margin-top: 23px;
}
.top-header .ivu-icon {
  float: left;
  line-height: 35px;
}
</style>
